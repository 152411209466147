<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <app-logo />

          <h2 class="brand-text text-primary ml-1">
            {{ $t("awards") || "awards" }}
          </h2>
        </b-link>

        <!-- <b-card-title class="mb-1"> Welcome to awards! 👋 </b-card-title> -->
        <!--
        <b-card-text class="mb-2">
          Please sign-in to your account and start the adventure
        </b-card-text> -->
        <ServerErrors v-if="serverErrs" :errors-bag="serverErrs" />
        <!-- form -->
        <!-- <validation-observer ref="loginForm" #default="{ invalid }"> -->
        <b-form class="auth-login-form mt-2" @submit.prevent="login">
          <!-- email -->
          <b-form-group label-for="email" :label="$t('email') || 'Email'">
            <!-- <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              > -->
            <b-form-input
              id="email"
              v-model="userEmail"
              type="email"
              name="login-email"
              placeholder="john@example.com"
            />
            <!-- :state="errors.length > 0 ? false : null" -->
            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
            <!-- </validation-provider> -->
          </b-form-group>

          <!-- password -->
          <b-form-group>
            <!-- <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{ name: 'forgot-password' }">
                  <small>Forgot Password?</small>
                </b-link>
              </div> -->
            <!-- <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              > -->
            <!-- :class="errors.length > 0 ? 'is-invalid' : null" -->
            <b-input-group class="input-group-merge">
              <b-form-input
                id="password"
                v-model="password"
                :type="passwordFieldType"
                class="form-control-merge"
                name="login-password"
                :placeholder="$t('password') || 'Password'"
              />
              <!-- :state="errors.length > 0 ? false : null" -->

              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
            <!-- </validation-provider> -->
          </b-form-group>

          <!-- submit button -->
          <AppButton variant="primary" type="submit" block :loading="isLoading">
            <!-- :disabled="invalid" -->
            {{ $t("login") || "Login" }}
          </AppButton>
        </b-form>
        <!-- </validation-observer> -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
// import { ValidationProvider, ValidationObserver } from "vee-validate";
import AppLogo from "@core/layouts/components/Logo.vue";
import {
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BForm,
  VBTooltip,
  BCard,
} from "bootstrap-vue";
// import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import { ServerErrors, AppButton } from "@/components/form/index";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCard,
    BForm,
    AppLogo,
    // ValidationProvider,
    // ValidationObserver,
    ServerErrors,
    AppButton,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: null,
      userEmail: null,
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      // validation rules
      // required,
      // email,
      serverErrs: null,
      isLoading: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    userIsSignedIn() {
      return !!(
        window.localStorage.getItem("token") &&
        window.localStorage.getItem("userData")
      );
    },
  },
  methods: {
    login() {
      this.isLoading = true;
      store.dispatch("auth/renderSidebarHandler", false);

      // this.$refs.loginForm.validate().then((success) => {
      //   if (success) {
      useJwt
        .login({
          email: this.userEmail,
          password: this.password,
        })
        .then((response) => {
          if (
            response.data &&
            response.data.status &&
            response.data.status == 400
          ) {
            this.serverErrs = response.data;
            return;
          }
          this.serverErrs = null;

          const userData = response.data.data;
          useJwt.setToken(userData.token);
          // useJwt.setRefreshToken(response.data.refreshToken);
          this.$store.dispatch("auth/setUserData", userData);
          localStorage.setItem("userData", JSON.stringify(userData));
          localStorage.setItem("token", userData.token);
          if (!localStorage.getItem("language")) {
            localStorage.setItem("language", "ar");
          }

          this.$router
            .replace(getHomeRouteForLoggedInUser(userData.role || "admin"))
            .then(() => {
              //  reload the instance for permissions to take effect
              this.$router.go();
            });
          // this.$router.replace("/");
        })
        .catch((error) => {
          // this.$refs.loginForm.setErrors(error.response.data.errors);
          this.serverErrs = error.response;
        })
        .finally(() => {
          this.isLoading = false;

          setTimeout(() => {
            store.dispatch("auth/renderSidebarHandler", true);
          }, 500);
        });
      // }
      // });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
